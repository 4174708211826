import { createTheme } from '@mui/material/styles';
// import { blueGrey, purple } from '@mui/material/colors';

export const theme = createTheme({
  typography: {
    allVariants: {
      color: '#0B1747',
    },
  },
  palette: {
    primary: {
      main: '#00B1FF',
      dark: '#3955FF',
    },
  },
});
